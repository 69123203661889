<template>
  <div class="m-x-10p mb:m-x-25">
    <h1 class="color-blue text-center font-osReg mb-10">Contact Me</h1>
    <p class="text-center">Whether you want to talk about working on a project, collaboration, or just to say hi, please feel free to send me a message.</p>
    <div class="w-full bg-white mt-25 mb-50 radius-25 small-shadow flex mb:flex-col">
      <img class="h-full w-250 radius-l-25 mobileHide" src="@/assets/images/contact-me.svg">
      <div class="w-full">
        <div class="p-25">
          <div class="flex mb-25 mb:flex-col gap-10 mb:w-full">
            <div class="flex-w-col w-50p mb:w-full">
              <span class="color-gray">First Name</span>
              <input class="mt-10 pb-5" type="text" placeholder="John" v-model="firstName">
            </div>
            <div class="flex-w-col w-50p mb:w-full">
              <span class="color-gray">Last Name</span>
              <input class="mt-10 pb-5" type="text" placeholder="Doe" v-model="lastName">
            </div>
          </div>
          <div class="flex mb-25 mb:flex-col gap-10 mb:w-full">
            <div class="flex-w-col w-50p mb:w-full">
              <span class="color-gray">E-mail Address</span>
              <input class="mt-10 pb-5" type="text" placeholder="johndoe@gmail.com" v-model="email">
            </div>
            <div class="flex-w-col w-50p mb:w-full">
              <span class="color-gray">Phone Number</span>
              <input class="mt-10 pb-5" type="text" placeholder="(000) 000-0000" v-model="number">
            </div>
          </div>
          <div class="flex mb:flex-col gap-10 mb:w-full">
            <div class="flex-w-col w-full">
              <span class="color-gray">Message</span>
              <textarea class="p-10 mt-10 osReg radius-5 no-resize" placeholder="Write your message..." cols="30" rows="10" v-model="message"></textarea>
            </div>
          </div>
          <p class="color-gray m-y-15">*Please allow 1-2 business days to respond to your message.</p>
          <a class="inline-block ml-a bg-blue color-white radius-15 p-y-10 p-x-25 cursor-pointer" @click="submit">SUBMIT</a>
          <p class="m-0 text-center font-robBold font-24" v-show="showSuccess">Message sent!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      number: '',
      message: '',
      showSuccess: false
    }
  },
  methods: {
    submit() {
      var xhttp = new XMLHttpRequest();
      var self = this;
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          self.showSuccess = true;
        }
      };
      xhttp.open("POST", "https://formspree.io/f/xpzbpwwo");
      xhttp.setRequestHeader('Accept', 'application/json');
      xhttp.send(JSON.stringify({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        number: this.number,
        message: this.message
      }));
    }
  }
}
</script>

<style scoped>
input {
  outline: 0;
  border-width: 0 0 2px;
}
</style>
